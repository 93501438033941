
import { Command, CommandAction, CommandValueType } from '@/model/Command'
import { commandService } from '@/services/command.service'
import { defineComponent } from 'vue'
import { Firmware } from '../model/firmware'

export default defineComponent({
    name: 'Card',
    components: {
    },

    props: {
        title: {
            type: String,
            required: true
        },
        bedIds: {
            type: Object as () => string[],
            default: undefined,
            required: true
        },
        propId: {
            type: String,
            default: undefined,
            required: false
        },
        firmware: {
            type: Object as () => Firmware[],
            required: false
        },
        commands: {
            type: Object as () => Command[],
            required: false
        }
    },

    data() {
        return {
            selectedFirmware: undefined as undefined | Firmware,
            forceFirmwareUpdate: false,
            selectedCommand: undefined as undefined | Command,
            selectedCommandTextError: '',
            selectedCommandCurrentValue: undefined as undefined | unknown,
            selectedCommandCurrentValueIsLoading: false
        }
    },

    methods: {

        isCommandBoolean() {
            return this.selectedCommand ? this.selectedCommand.valueType == CommandValueType[CommandValueType.Boolean] : false
        },

        isCommandText() {
            return true
        },

        doCancel() {
            this.$emit('close')
        },

        inputIsValid() {
            if (!this.selectedCommand) {
                return false
            }
            if (this.selectedCommand.valueType == CommandValueType[CommandValueType.Unsigned] ||
                this.selectedCommand.valueType == CommandValueType[CommandValueType.Signed] ||
                this.selectedCommand.valueType == CommandValueType[CommandValueType.Float]) {
                const value = Number.parseFloat(this.selectedCommand.value as string)
                
                if (this.selectedCommand.valueType != CommandValueType[CommandValueType.Float]) {
                    if (!Number.isInteger(value)) {
                        this.selectedCommandTextError = 'Value should be a number'
                        return false
                    }
                }
                if (Number.isNaN(value)) {
                    this.selectedCommandTextError = 'Value should be a number'
                    return false
                }
                if (value < 0 && this.selectedCommand.valueType == CommandValueType[CommandValueType.Unsigned]) {
                    this.selectedCommandTextError = 'Value should be a positive number'
                    return false
                }
                this.selectedCommandTextError = ''
                return true
            } else if (this.selectedCommand.valueType == CommandValueType[CommandValueType.Hex]) {
                const value = this.selectedCommand.value as string
                const regex = /0x[0-9A-F]+$/i
                if (!regex.test(value)) {
                    this.selectedCommandTextError = 'Value should be hex and should start with 0x'
                    return false
                }
                this.selectedCommandTextError = ''
                return true
            } else if (this.selectedCommand.valueType == CommandValueType[CommandValueType.String]) {
                const value = this.selectedCommand.value as string
                if(value.length > this.selectedCommand.valueByteLength) {
                    this.selectedCommandTextError = `String should be shorter than ${this.selectedCommand.valueByteLength} characters`
                    return false
                }
                return true
            }
            return true
        },

        async getCurrentBedCommandValue() {
            if (this.selectedCommand == undefined || !this.selectedCommand.isReadable) {
                return
            }
            this.selectedCommandCurrentValueIsLoading = true
            //Copy the command for manipulation
            const command  = Object.assign({}, this.selectedCommand)
            command.action = CommandAction[CommandAction.Read]
            const value = await commandService.getBedCommandValue(this.bedIds[0], command)
            //Check if we still want the result for this command or if the command has changed by the user
            if (this.selectedCommand && this.selectedCommand.id == command.id) {
                this.selectedCommandCurrentValue = value
                this.selectedCommandCurrentValueIsLoading = false
            }
        },

        onCommandSelected() {
            this.selectedCommandCurrentValue = undefined
            if (this.bedIds != undefined && this.bedIds.length == 1) {
                this.getCurrentBedCommandValue()
            }
        },

        doConfirm() {
            if (this.firmware) {
                if (this.selectedFirmware == undefined) {
                    return
                }
                this.$emit('confirm', this.selectedFirmware, this.forceFirmwareUpdate)
            }
            else if (this.commands && this.selectedCommand) {
                if (!this.inputIsValid()) {
                    return
                }
                this.selectedCommand.action = CommandAction[CommandAction.Write]
                //Set value to false when the user didn't touch the checkbox
                if (this.isCommandBoolean() && this.selectedCommand.value == undefined) {
                    this.selectedCommand.value = false
                }
                this.$emit('confirm', this.selectedCommand)
            }
        }
    }
})