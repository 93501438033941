<template>
    <div class="overlay-container">
        <div class="overlay-card" v-click-away="doCancel">
            <div class="overlay-card-header">
                <div class="overlay-card-caption">
                    {{ title }}
                </div>
                <div class="overlay-card-multiple">
                    <i v-if="bedIds.length > 1" class="material-icons" v-tooltip="{ content: 'Changing this will update all selected beds from the filter', classes: 'tooltip' }">feedback</i>
                </div>
                <div class="overlay-card-close">
                    <a v-on:click="doCancel()"><i class="material-icons">close</i></a>
                </div>
            </div>
            <div class="overlay-card-content">

                <div class="input-header" v-if="propId">ID</div>
                <input v-if="propId" v-model="propId" class="input-disabled" disabled>

                <div v-if="firmware">
                    <div class="input-header">Select firmware</div>
                    <!-- https://vuejs.org/v2/guide/forms.html#Select -->
                    <select class="input-dropdown" v-model="selectedFirmware">
                        <option v-for="option in firmware" :key="option.version" :value="option">
                            {{ 'Package: ' + option.version }}
                        </option>
                    </select>

                    <div v-if="selectedFirmware">
                        <div class="input-header">Options</div>
                        <div class="input-checkbox-container">
                            <input type="checkbox" id="checkbox" class="input-checkbox" v-model="forceFirmwareUpdate">
                            <label for="checkbox" class="input-checkbox-value">Force firmware update</label>
                        </div>
                        
                        <div class="note">
                            <div class="note-caption">Firmware details</div>
                            <div class="note-icon">
                                <i class="material-icons">push_pin</i>
                            </div>
                            <div>Package version: {{ selectedFirmware.version }}</div>
                            <div>ESP version: {{ selectedFirmware.espVersion }}</div>
                            <div>Main version: {{ selectedFirmware.mainVersion }}</div>
                            <div>Remote version: {{ selectedFirmware.remoteVersion }}</div>
                            <p></p>
                        </div>
                    </div>
                </div>

                <div v-if="commands">
                    <div class="input-header">Select command</div>
                    <!-- https://vuejs.org/v2/guide/forms.html#Select -->
                    <select class="input-dropdown" v-model="selectedCommand" @change="onCommandSelected()">
                        <option v-for="command of commands" :key="command" :value="command">
                            {{ command.name }}
                        </option>
                    </select>

                    <div v-if="selectedCommand">
                        <div v-if="selectedCommand.isReadable && bedIds.length == 1">
                            <div class="input-header">Current value</div>
                            <div v-if="selectedCommandCurrentValueIsLoading" style="width: 50px;">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin:auto;background:#fff;display:block;" width="50px" height="50px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                                    <circle cx="50" cy="50" fill="none" stroke="#926d4f" stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138">
                                        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="0.9615384615384615s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                                    </circle>
                                </svg>
                            </div>
                            <div class="input-command-read-error-container" v-else-if="selectedCommandCurrentValue == undefined || selectedCommandCurrentValue == null">
                                <span class="material-icons">error</span>
                                <span>Failed retrieving current value</span>
                            </div>
                            <div class="input-checkbox-container" v-else-if="isCommandBoolean()">
                                <input type="checkbox" id="checkbox" class="input-checkbox" v-model="selectedCommandCurrentValue" disabled>
                                <label for="checkbox" class="input-checkbox-value">{{ selectedCommand.name }}</label>
                            </div>
                            <div class="input-text-container" v-else-if="isCommandText()">
                                <input type="text" 
                                    id="text"
                                    class="input-text"
                                    v-model="selectedCommandCurrentValue"
                                    disabled>
                            </div>
                        </div>

                        <div class="input-header">Value</div>
                        <div class="input-checkbox-container" v-if="isCommandBoolean()">
                            <input type="checkbox" id="checkbox" class="input-checkbox" v-model="selectedCommand.value">
                            <label for="checkbox" class="input-checkbox-value">{{ selectedCommand.name }}</label>
                        </div>
                        <div class="input-text-container" v-else-if="isCommandText()">
                            <input type="text" 
                                id="text"
                                class="input-text"
                                :class="{ 'input-text-error': selectedCommandTextError.length > 0 }"
                                v-model="selectedCommand.value">
                            <label for="text" class="input-text-value">{{ selectedCommandTextError }}</label>
                        </div>
                    </div>
                </div>

                <div class="overlay-card-content-button">
                    <button class="button" v-on:click="doConfirm()">{{ firmware ? "Update firmware" : "Run command" }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="./card.ts"></script>
<style src="./card.scss" lang="scss"></style>