import Keycloak from 'keycloak-js';
import moment from 'moment-timezone';
import VTooltip from 'v-tooltip';
import { createApp } from 'vue';
import VueClickAway from "vue3-click-away";

import App from './App.vue';
import { Constants } from './constants';
import router from './router';
import { keycloakService } from './services/keycloak.service';
import { tokenService } from './services/token.service';
import store from './store';

const keycloak = Keycloak({
	url: process.env.VUE_APP_KEYCLOAK_AUTH_URL,
	realm: process.env.VUE_APP_KEYCLOAK_REALM,
	clientId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID
});

keycloak.init({ onLoad: 'login-required' }).then(async (auth) => {
	if(!auth) {
		window.location.reload();
	}

	tokenService.storeToken(keycloak.token ? keycloak.token : '')
	tokenService.storeRefreshToken(keycloak.refreshToken ? keycloak.refreshToken : '')
	await keycloakService.setKeycloak(keycloak)

	// Reset settings if different user logged in
	const email = localStorage.getItem(Constants.LAST_LOGGED_IN_EMAIL)
	if (email != keycloakService.keycloakUser?.email) {
		localStorage.setItem(Constants.LAST_LOGGED_IN_EMAIL, keycloakService.keycloakUser?.email ?? "")
		localStorage.removeItem(Constants.BEDS_SELECTED_IDS_KEY)
		localStorage.removeItem(Constants.SETTINGS_BED_IDS_KEY)
		localStorage.removeItem(Constants.SETTINGS_SELECTED_IDS_KEY)
	}

	const app = createApp(App)
	app.use(store)
	app.use(moment as any)
	app.use(VTooltip)
	app.use(router)
	app.use(VueClickAway)
	app.mount('#app');

	setInterval(() => {
		keycloak.updateToken(70).then((refreshed) => {
			if (refreshed) {
				// console.log('Token refreshed'+ refreshed);
				tokenService.storeToken(keycloak.token ? keycloak.token : '');
			} else {
				// console.log('Token not refreshed, valid for '
				// + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
			}
		}).catch(() => {
			// console.log('Failed to refresh token')
		});
	}, process.env.VUE_APP_KEYCLOAK_REFRESH);
}).catch(() => {
	// console.log('Authentication Failed')
})
