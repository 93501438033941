import Navigation from '@/components/navigation/Navigation.vue';
import { Constants } from "@/constants";
import { Freshbed } from "@/model/Freshbed";
import { bedService } from "@/services/bed.service";
import { defineComponent } from "vue";

export default defineComponent({
    name: 'Accounts',
    components: {
        Navigation
    },

    data() {
        return {
            beds: Array<Freshbed>(),
            bedIds: Array<string>(),
            selectedBedIds: Array<string>(),
            users: [] as string[],
            open: true,
            showList: false
        }
    },

    async mounted() {
        this.selectedBedIds = []
        this.beds = []

        const bedIdsData = localStorage.getItem(Constants.SETTINGS_BED_IDS_KEY)
        if (bedIdsData)
        this.bedIds = JSON.parse(bedIdsData)

        const selectedIdsData = localStorage.getItem(Constants.SETTINGS_SELECTED_IDS_KEY)
        if (selectedIdsData)
            this.selectedBedIds = JSON.parse(selectedIdsData)

        const beds = await bedService.getBeds()
        this.beds = beds.filter(bed => this.bedIds.includes(bed.id))

        this.updateData()
    },

    methods: {

        async updateData(): Promise<void> {
            const beds = await bedService.getBeds(true)
            this.beds = beds.filter(bed => this.bedIds.includes(bed.id))
            
            this.users = []
            this.beds.forEach(bed => {
                bed.users.forEach(user => {
                    this.users.push(user.emailAddress)
                })
            })
        },

        toggleList() {
            this.showList = !this.showList
        },

        save(): void {
            localStorage.setItem(Constants.SETTINGS_SELECTED_IDS_KEY, JSON.stringify(this.selectedBedIds))
        },

        check(bed: Freshbed): void {
            const selectedIndex = this.selectedBedIds.indexOf(bed.id)
            if (selectedIndex > -1) {
                this.selectedBedIds.splice(selectedIndex, 1)
            } else {
                this.selectedBedIds.push(bed.id)
            }
            this.save()
            this.updateData()
        },

        checkAll(): void {
            if (this.beds.length == this.selectedBedIds.length)
                this.selectedBedIds = []
            else
                this.selectedBedIds = this.beds.map(bed => bed.id)
            this.save()
            this.updateData()
        },

        showFilter(): void {
            this.open = !this.open
        },
        
    }

  });