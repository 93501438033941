import { Firmware } from '@/model/Firmware';

import { Constants } from '../constants';
import { ApiError, apiService } from './api.service';
import { Command, CommandAction } from '@/model/Command';

export class CommandService {

    public async getCommandsForBeds(beds: string[]): Promise<Command[]> {
        const query = beds.map(bed => `bedId=${bed}&`).join('')
        try {
            const endpoint = `${Constants.ENDPOINT_COMMANDS}?${query}`
            const response = await apiService.get(endpoint)
            return response.data
        }catch(error) {
            throw new ApiError(error)
        }
    }

    public async getCommandsForBed(bedId: string): Promise<Command[]> {
        try {
            const endpoint = `${Constants.ENDPOINT_BEDS}/${bedId}/commands`
            const response = await apiService.get(endpoint)
            return response.data
        }catch(error) {
            throw new ApiError(error)
        }
    }

    public async getCommandForBed(bedId: string, command: Command): Promise<Command | undefined> {
        try {
            const endpoint = `${Constants.ENDPOINT_BEDS}/${bedId}/commands/${command.id}`
            const response = await apiService.get(endpoint)
            let commands: Command[] = response.data
            commands = commands.filter(commandFromApi => commandFromApi.action == command.action)
            return commands.length == 1 ? commands[0] : undefined
        }catch(error) {
            throw new ApiError(error)
        }
    }

    public async getPendingWriteCommandsForBed(bedId: string): Promise<Command[]> {
        try {
            const endpoint = `${Constants.ENDPOINT_BEDS}/${bedId}/commands`
            const response = await apiService.get(endpoint)
            const commands: Command[] = response.data
            return commands.filter(command => command.action == CommandAction[CommandAction.Write])
        }catch(error) {
            throw new ApiError(error)
        }
    }

    public async executeBedCommand(bedId: string, command: Command, waitForAnswer: boolean): Promise<Command | undefined> {
        try {
            const endpoint = `${Constants.ENDPOINT_BEDS}/${bedId}/commands${waitForAnswer ? '?waitForAnswer=true' : ''}`
            const data = [{
                id: command.id,
                action: command.action,
                value: command.value
            }]
            const response = await apiService.post(endpoint, data)
            if (waitForAnswer && response.data.length == 1) {
                return response.data[0]
            }
            return undefined
        }
        catch(error) {
            throw new ApiError(error)
        }
    }

    public async getBedCommandValue(bedId: string, command: Command): Promise<unknown> {
        const response = await this.executeBedCommand(bedId, command, true)
        return response?.value
    }

    public async changeBedFirmware(bedId: string, firmware: Firmware, force: boolean): Promise<void> {
        try {
            const endpoint = `${Constants.ENDPOINT_BEDS}/${bedId}/commands`
            try {
                await apiService.delete(endpoint + '/request_firmware_update')
            }
            catch(error) {
                undefined
            }

            try {
                await apiService.delete(endpoint + '/force_firmware_update')
            }
            catch(error) {
                undefined
            }

            const data = [{
                id: force ? "force_firmware_update" : "request_firmware_update",
                action: CommandAction[CommandAction.Write],
                value: firmware.version
            }]
            const response = await apiService.post(endpoint, data)
            return response.data
        }
        catch(error) {
            throw new ApiError(error)
        }
    }
}

// Export a singleton instance in the global namespace
export const commandService = new CommandService();