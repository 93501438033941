import { Freshbed } from '@/model/Freshbed';
import { Log } from '@/model/Log';

import { Constants } from '../constants';
import { ApiError, apiService } from './api.service';

export class LogService {

    public async getLogs(beds: Freshbed[]): Promise<Log[]> {
        try {
            const queries = beds.map(bed => `bedId=${bed.id}&`)
            const endpoint = `${Constants.ENDPOINT_LOGS}?${queries.join('')}`
            const response = await apiService.get(endpoint)
            return response.data
        }catch(error) {
            throw new ApiError(error)
        }
    }

    public async getLog(bed: Freshbed): Promise<Log | undefined> {
        try {
            const endpoint = `${Constants.ENDPOINT_LOGS}?bedId=${bed.id}`
            const response = await apiService.get(endpoint)
            if (response.data.length > 0)
                return response.data[0]
            return undefined
        }catch(error) {
            throw new ApiError(error)
        }
    }
}

// Export a singleton instance in the global namespace
export const logService = new LogService();