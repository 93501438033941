import { Freshbed } from '@/model/Freshbed';

import { Constants } from '../constants';
import { ApiError, apiService } from './api.service';

export class BedService {

    public async getBeds(detailed?: boolean, q?: string): Promise<Freshbed[]> {
        q = q ? `q=${q}&` : ''
        const detailedString = detailed ? 'detailed='+detailed : ''
        try {
            const endpoint = `${Constants.ENDPOINT_BEDS}?${q}${detailedString}`
            const response = await apiService.get(endpoint)
            let beds: Freshbed[] = response.data
            beds = beds.map(bed => {
                bed.lastCheckedDate = new Date(bed.lastCheckedDate)
                return bed
            })
            return beds
        }catch(error) {
            throw new ApiError(error)
        }
    }

    public async getBed(bedId: string): Promise<Freshbed> {
        try {
            const endpoint = `${Constants.ENDPOINT_BEDS}/${bedId}`
            const response = await apiService.get(endpoint)
            const bed: Freshbed = response.data
            bed.lastCheckedDate = new Date(bed.lastCheckedDate)
            return bed
        }catch(error) {
            throw new ApiError(error)
        }
    }

    public async setExtendedLogging(bedId: string, enabled: boolean): Promise<boolean> {
        try {
            const endpoint = `${Constants.ENDPOINT_BEDS}/${bedId}`
            const data = {
                extendedLoggingEnabled: enabled
            }
            const response = await apiService.patch(endpoint, data)
            return true
        }catch(error) {
            return false
        }
    }
}

// Export a singleton instance in the global namespace
export const bedService = new BedService();