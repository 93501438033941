import axios, { AxiosRequestConfig } from 'axios';

import { tokenService } from './token.service';

export class ApiError extends Error {
    public errorCode: any;

    constructor(error: any) {
        super(error.response.statusText)
        this.name = this.constructor.name
        this.message = error.response.statusText || error.response.data
        this.errorCode = error.response.status
    }
}

export class ApiService {

    public init(baseUrl: string): void {
        axios.defaults.baseURL = baseUrl
    }

    public setHeader(): void {
        axios.defaults.headers.common['Authorization'] = `Bearer ${tokenService.getToken()}`
    }

    public removeHeader(): void {
        axios.defaults.headers.common = {}
    }

    public get(resource: string): Promise<any> {
        this.setHeader()
        return axios.get(resource)
    }

    public post(resource: string, data: any): Promise<any> {
        this.setHeader()
        return axios.post(resource, data)
    }

    public patch(resource: string, data: any): Promise<any> {
        this.setHeader()
        return axios.patch(resource, data)
    }

    public put(resource: string, data: any): Promise<any> {
        this.setHeader()
        return axios.put(resource, data)
    }

    public delete(resource: string): Promise<any> {
        return axios.delete(resource)
    }

    public customRequest(data: AxiosRequestConfig): Promise<any> {
        this.setHeader()
        return axios(data)
    }

}

// Export a singleton instance in the global namespace
export const apiService = new ApiService();