import { Constants } from '../constants';

export class TokenService {

    public getToken(): string | null {
        return localStorage.getItem(Constants.TOKEN_KEY)
    }

    public storeToken(token: string): void {
        localStorage.setItem(Constants.TOKEN_KEY, token)
    }

    public storeRefreshToken(refreshToken: string): void {
        localStorage.setItem(Constants.REFRESH_TOKEN_KEY, refreshToken)
    }

    public invalidateToken(): void {
        localStorage.removeItem(Constants.TOKEN_KEY)
        localStorage.removeItem(Constants.REFRESH_TOKEN_KEY)
    }
}

// Export a singleton instance in the global namespace
export const tokenService = new TokenService();