<template>
    <div class="navigation-bar">
        <router-link to="/beds" class="navigation-bar-item navigation-bar-link" :class="selectTab('beds')">Freshbeds</router-link>
        <router-link to="/settings" class="navigation-bar-item navigation-bar-link" :class="selectTab('settings')">Bed Settings</router-link>
        <router-link to="/accounts" class="navigation-bar-item navigation-bar-link" :class="selectTab('accounts')">Accounts</router-link>
        <a class="navigation-bar-item navigation-bar-icon" v-on:click="logout()">
            <i class="material-icons-outlined">power_settings_new</i>
        </a>
    </div>
</template>

<script src="./navigation.ts"></script>
<style src="./navigation.scss" lang="scss"></style>