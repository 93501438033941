import { Role } from '@/model/Role';
import Keycloak from 'keycloak-js';
import { ApiError, apiService } from './api.service';

import { Constants } from '../constants';
import { KeycloakUser } from '../model/KeycloakUser';

export class KeycloakService {

    public keycloak: Keycloak.KeycloakInstance | undefined;
    public keycloakUser: KeycloakUser | undefined;

    public async setKeycloak(keycloak: Keycloak.KeycloakInstance) {
        this.keycloak = keycloak
        await this.loadKeycloakUser()
    }

    public async loadKeycloakUser() {
        try {
            const endpoint = `${Constants.ENDPOINT_USERINFO}`
            const response = await apiService.get(endpoint)
            this.keycloakUser = response.data
        }catch(error) {
            throw new ApiError(error)
        }
    }

    public isAuthorizedForRole(role: Role) {
        return this.keycloakUser ? this.keycloakUser.roles.includes(role) : false
    }

    public isAuthorized(roles: [string]) {
        return this.keycloakUser ? this.keycloakUser.roles.some(o => roles == undefined || roles && roles.includes(o)) : false
    }

    public hasAccessToDashboard() {
        return this.isAuthorizedForRole(Role.FreshbedAdmin) || this.isAuthorizedForRole(Role.HotelChain)
    }

    public logout() {
        if (this.keycloak) {
            this.keycloak.logout()
        }
    }
}

// Export a singleton instance in the global namespace
export const keycloakService = new KeycloakService();