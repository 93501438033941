import { defineComponent } from 'vue';

import { keycloakService } from '../../services/keycloak.service';
import { tokenService } from '../../services/token.service';

export default defineComponent({
    name: 'Navigation',
    components: {

    },

    props: {
        selected: String
    },

    methods: {

        selectTab(target: string): string {
            return this.selected == target ? 'navigation-bar-item-selected' : ''
        },

        logout(): void {
            tokenService.invalidateToken()
            keycloakService.logout()
        }
        
    }

});
