import { keycloakService } from '@/services/keycloak.service';
import { tokenService } from '@/services/token.service';
import Beds from '@/views/beds/Beds.vue';
import Settings from '@/views/settings/Settings.vue';
import Accounts from '@/views/accounts/Accounts.vue';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const BASE_TITLE = 'Freshbed Insights'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    alias: '/beds',
    name: 'Beds',
    component: Beds,
    meta: {
      public: false,
      showWhenLoggedOut: false,
      title: `${BASE_TITLE} - Beds`
    }
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
    meta: {
      public: false,
      showWhenLoggedOut: false,
      title: `${BASE_TITLE} - Settings`
    }
  },
  {
    path: '/accounts',
    name: 'Accounts',
    component: Accounts,
    meta: {
      public: false,
      showWhenLoggedOut: false,
      title: `${BASE_TITLE} - Accounts`
    }
  },
  {
    path: "/:catchAll(.*)",
    component: Beds
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach((to, from, next) => {
  // Remove the hash and Keycloak stuff from the url
  to.fullPath = to.fullPath.split('#')[0];

  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) document.title = nearestWithTitle.meta.title;

  const showWhenLoggedOut = to.matched.some(
      (record) => record.meta.showWhenLoggedOut
  );
  const isLoggedIn = !!tokenService.getToken();
  const isAuthorized = keycloakService.isAuthorized(to.meta.authorized);

  if (!isAuthorized || (isLoggedIn && showWhenLoggedOut)) {
    next('/');
  }

  next();
});

export default router
