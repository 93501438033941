<template>
    <div class="grid">
        <Navigation selected="settings"/>

        <div class="grid-content grid-content-settings">
            <div class="grid-settings-filter">

                <div class="settings-filter">
                    <div class="settings-filter-header" v-on:click="showFilter()">
                        <span>Available beds</span>
                        <div class="settings-filter-header-icon">
                            <i class="material-icons" v-if="open">remove</i>
                            <i class="material-icons" v-else>add</i>
                        </div>
                    </div>

                    <div class="settings-filter-content" :class="{ 'settings-filter-hide' : !open }">
                        <button class="button button-settings" v-on:click="checkAll()">{{ selectedBedIds.length >= beds.length ? "Deselect all" : "Select all"}}</button>

                        <div class="settings-filter-row" v-for="(bed) of beds" v-bind:key="bed" v-on:click="check(bed)">
                            <div class="settings-filter-icon">
                                <i class="material-icons settings-filter-icon-active" v-if="selectedBedIds.includes(bed.id)">check_box</i>
                                <i class="material-icons" v-else>check_box_outline_blank</i>
                            </div>
                            <div class="settings-filter-data">
                                <span class="settings-filter-data-active" v-if="bed.checked">
                                    {{ bed.id }}
                                    <span v-if="bed.name">({{ bed.name }})</span>
                                </span>
                                <span v-else>
                                    {{ bed.id }}
                                    <span v-if="bed.name">({{ bed.name }})</span>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="note">
                        <div class="note-caption">Note</div>
                        <div class="note-icon">
                            <i class="material-icons">push_pin</i>
                        </div>
                        <span>The available beds are the result of the filter on the Freshbed tab</span>
                        <p>
                            <router-link to="/beds" class="note-link">Change filter</router-link>
                        </p>
                    </div>

                </div>

            </div>

            <div class="grid-settings-result" v-if="this.selectedBedIds.length > 0">

                <div class="settings-grid-values">
                    <div class="settings-grid-value">
                        <div class="settings-grid-value-caption">
                            {{ this.selectedBedIds.length > 1 ? "Average" : "Bed" }}<br />Temperature
                        </div>
                        <apexchart height="250" type="radialBar" :options="chartOptions" :series="chartTemperature"></apexchart>
                        <div class="settings-graph-value">{{ currentBedTemperature ? `${currentBedTemperature}°` : `` }}</div>
                        <div class="settings-graph-subtitle">{{ this.selectedBedIds.length > 1 ? "Average" : "" }} target temperature</div>
                        <div class="settings-graph-subtitle-value">{{ targetTemperature ? `${targetTemperature}°` : `` }}</div>
                    </div>
                    <div class="settings-grid-value">
                        <div class="settings-grid-value-caption">
                            {{ this.selectedBedIds.length > 1 ? "Average" : "Bed" }}<br />Fan Speed
                        </div>
                        <apexchart height="250" type="radialBar" :options="chartOptions" :series="chartFanSpeed"></apexchart>
                        <div class="settings-graph-value">{{ currentFanSpeed ? currentFanSpeed : '' }}%</div>
                        <div class="settings-graph-subtitle">{{ this.selectedBedIds.length > 1 ? "Average" : "" }} target speed</div>
                        <div class="settings-graph-subtitle-value">{{ targetFanSpeed ? targetFanSpeed : '' }}%</div>
                    </div>
                </div>
                
                <div class="settings-more">
                    <i class="material-icons">arrow_downward</i>
                </div>

                <div class="settings-data-row" v-if="id != undefined">
                    <div class="settings-data-row-caption">ID</div>
                    <div class="settings-data-row-icon"></div>
                    <div class="settings-data-row-value">{{ id }}</div>
                    <div class="settings-data-row-icon"></div>
                </div>

                <div class="settings-data-row" v-if="name != undefined">
                    <div class="settings-data-row-caption">Name</div>
                    <div class="settings-data-row-icon"></div>
                    <div class="settings-data-row-value">{{ name }}</div>
                    <div class="settings-data-row-icon"></div>
                </div>

                <div class="settings-data-row" v-if="groupName != undefined">
                    <div class="settings-data-row-caption">Group name</div>
                    <div class="settings-data-row-icon"></div>
                    <div class="settings-data-row-value">{{ groupName }}</div>
                    <div class="settings-data-row-icon"></div>
                </div>

                <div class="settings-data-row" v-if="lastSeen != undefined">
                    <div class="settings-data-row-caption">Last seen</div>
                    <div class="settings-data-row-icon"></div>
                    <div class="settings-data-row-value">{{ lastSeen }}</div>
                    <div class="settings-data-row-icon"></div>
                </div>

                <div class="settings-data-row" v-if="dateWebsocketConnected != undefined">
                    <div class="settings-data-row-caption">Date websocket connected</div>
                    <div class="settings-data-row-icon"></div>
                    <div class="settings-data-row-value">{{ dateWebsocketConnected }}</div>
                    <div class="settings-data-row-icon"></div>
                </div>

                <div class="settings-data-row">
                    <div class="settings-data-row-caption">{{ this.selectedBedIds.length > 1 ? "Average" : "" }} bed temperature</div>
                    <div class="settings-data-row-icon"></div>
                    <div class="settings-data-row-value">{{ currentBedTemperature ? `${currentBedTemperature} degrees` : `-` }}</div>
                    <div class="settings-data-row-icon"></div>
                </div>
                
                <div class="settings-data-row">
                    <div class="settings-data-row-caption">{{ this.selectedBedIds.length > 1 ? "Average" : "" }} target temperature</div>
                    <div class="settings-data-row-icon"></div>
                    <div class="settings-data-row-value">{{ targetTemperature ? `${targetTemperature} degrees` : `-` }}</div>
                    <div class="settings-data-row-icon"></div>
                </div>
                
                <div class="settings-data-row">
                    <div class="settings-data-row-caption">{{ this.selectedBedIds.length > 1 ? "Average" : "" }} bed humidity</div>
                    <div class="settings-data-row-icon"></div>
                    <div class="settings-data-row-value">{{ currentBedHumidity ? `${currentBedHumidity}%` : `-` }}</div>
                    <div class="settings-data-row-icon"></div>
                </div>
                
                <div class="settings-data-row">
                    <div class="settings-data-row-caption">{{ this.selectedBedIds.length > 1 ? "Average" : "" }} fan speed</div>
                    <div class="settings-data-row-icon"></div>
                    <div class="settings-data-row-value">{{ currentFanSpeed ? `${currentFanSpeed}%` : `-` }}</div>
                    <div class="settings-data-row-icon"></div>
                </div>
                
                <div class="settings-data-row">
                    <div class="settings-data-row-caption">{{ this.selectedBedIds.length > 1 ? "Average" : "" }} target fan speed</div>
                    <div class="settings-data-row-icon"></div>
                    <div class="settings-data-row-value">{{ targetFanSpeed ? `${targetFanSpeed}%` : `-` }}</div>
                    <div class="settings-data-row-icon"></div>
                </div>

                <div class="settings-data-row">
                    <div class="settings-data-row-caption">{{ this.selectedBedIds.length > 1 ? "Average" : "" }} room temperature</div>
                    <div class="settings-data-row-icon"></div>
                    <div class="settings-data-row-value">{{ currentRoomTemperature ? `${currentRoomTemperature} degrees` : `-` }}</div>
                    <div class="settings-data-row-icon"></div>
                </div>

                <div class="settings-data-row">
                    <div class="settings-data-row-caption">{{ this.selectedBedIds.length > 1 ? "Average" : "" }} room humidity</div>
                    <div class="settings-data-row-icon"></div>
                    <div class="settings-data-row-value">{{ currentRoomHumidity ? `${currentRoomHumidity}%` : `-` }}</div>
                    <div class="settings-data-row-icon"></div>
                </div>
                
                <div class="settings-data-row">
                    <div class="settings-data-row-caption">Extended logging</div>
                    <div class="settings-data-row-icon"></div>
                    <div class="settings-data-row-value">
                        <span v-on:click="toggleExtendedLogging()">
                            <i class="material-icons value-checkbox-active" v-if="extendedLoggingEnabled == 'yes'">check_box</i>
                            <i class="material-icons value-checkbox" v-else-if="extendedLoggingEnabled == 'no'">check_box_outline_blank</i>
                            <i class="material-icons value-checkbox-active" v-else>indeterminate_check_box</i>
                        </span>
                    </div>
                    <div class="settings-data-row-icon"></div>
                </div>

                <div class="settings-data-row" v-if="espVersions.length > 1 || mainVersions.length > 1 || remoteVersions.length > 1">
                    <div class="settings-data-row-caption">Firmware version</div>
                    <div class="settings-data-row-icon settings-data-row-icon-highlight">
                        <i class="material-icons" v-tooltip="{ content: 'Changing this setting will update all selected beds from the filter', classes: 'tooltip' }">feedback</i>
                    </div>
                    <div class="settings-data-row-value">
                        <span class="value-multiple">Multiple values</span>
                    </div>
                    <div class="settings-data-row-icon">
                        <a v-on:click="toggleFirmwareCard()"><i class="material-icons">more_horiz</i></a>
                    </div>
                </div>
                <div class="settings-data-row settings-data-row-firmware" v-else>
                    <div class="settings-data-row-caption">Firmware version</div>
                    <div class="settings-data-row-icon settings-data-row-icon-highlight"></div>
                    <div class="settings-data-row-value settings-data-row-value-firmware">
                        <div class="value-list">Package: {{ versions.length == 1 ? versions[0] : '-' }}</div>
                        <div class="value-list">ESP: {{ espVersions.length == 1 ? espVersions[0] : '-' }}</div>
                        <div class="value-list">Main: {{ mainVersions.length == 1 ? mainVersions[0] : '-' }}</div>
                        <div class="value-list">Remote: {{ remoteVersions.length == 1 ? remoteVersions[0] : '-' }}</div>
                    </div>
                    <div class="settings-data-row-icon" v-if="isFreshbedAdmin()">
                        <a v-on:click="toggleFirmwareCard()"><i class="material-icons">more_horiz</i></a>
                    </div>
                </div>

                <div class="settings-data-row" v-if="getPendingFirmwareUpdates().length > 0">
                    <div class="settings-data-row-caption">Pending firmware update</div>
                    <div class="settings-data-row-icon settings-data-row-icon-highlight"></div>
                    <div class="settings-data-row-value" v-if="getPendingFirmwareUpdates().length > 1">
                        <div>Some beds are currently updating</div>
                    </div>
                    <div class="settings-data-row-value" v-else>
                        <div>Package: {{ getPendingFirmwareUpdates()[0].value }}</div>
                    </div>
                </div> 

                <div class="settings-data-row" v-if="isFreshbedAdmin()">
                    <div class="settings-data-row-caption">Pending commands</div>
                    <div class="settings-data-row-icon settings-data-row-icon-highlight"></div>
                    <div class="settings-data-row-value" v-if="getVisiblePendingCommands().length > 0">
                        <div class="value-list" v-for="(command) of getVisiblePendingCommands()" v-bind:key="command">{{ command.name }}: {{ command.value }}</div>
                    </div>
                    <div class="settings-data-row-value" v-else>
                        <div>No pending commands</div>
                    </div>
                    <div class="settings-data-row-icon">
                        <a v-on:click="toggleCommandCard()"><i class="material-icons">more_horiz</i></a>
                    </div>
                </div> 
                <br /><br />
                
            </div>

            <div class="grid-settings-no-result" v-else>
                Please select one or more beds.
                <br />
                If there are no beds available, try to <router-link to="/beds" class="note-link">change the filter</router-link> 
            </div>

        </div>
        <Card :title="'Update firmware'"
            :bedIds="this.selectedBedIds"
            :firmware="firmware"
            v-if="showFirmwareCard" v-on:close="toggleFirmwareCard" v-on:confirm="updateFirmware"/>
        <Card :title="'Execute command'"
            :bedIds="this.selectedBedIds"
            :commands="this.availableBedCommands"
            v-if="showCommandCard" v-on:close="toggleCommandCard" v-on:confirm="executeCommand"/>
    </div>
</template>

<script src="./settings.ts"></script>
<style src="./settings.scss" lang="scss"></style>