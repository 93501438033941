export enum CommandValueType {
    Unsigned = "Unsigned",
    Signed = "Signed",
    Float = "Float",
    Boolean = "Boolean",
    Hex = "Hex",
    String = "String"
}

export enum CommandAction {
    Read = "Read",
    Write = "Write"
}

export interface Command {
    id: string;
    name: string;
    valueType: string;
    valueByteLength: number;
    isVisibleInUI: boolean;
    isReadable: boolean;
    isWritable: boolean;
    action: string;
    value: unknown;
}