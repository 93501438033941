<template>
    <div id="app" v-if="hasAccessToDashboard">
        <router-view />
    </div>
    <div v-else>
        <div class="no-access-container">
            <div class="no-access-logo">
                    Freshbed
                <div class="no-access-description">
                    Your account has no access to this dashboard, please contact Freshbed to gain access.
                    <br /><br />
                    If you want to use another account, click <a href="javascript:" v-on:click="logout()">here</a>.
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">

@import url("https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined:200,300,400,500>");
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Rounded:opsz,wght,FILL,GRAD@48,300,0,0");
@import url("https://fonts.googleapis.com/css?family=Maven+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i&display=swap");
@import "./assets/styles.scss";

BODY, HTML {
    margin: 0px;
    padding: 0px;
    height: 100%;
}

#app {
    font-family: 'Maven Pro', serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    height: 100%;
}


.no-access-container {
    background-color: #FFF;
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    text-align: center;
}

.no-access-logo {
    box-sizing: border-box;
    color: #212D42;
    font-family: "Montserrat", sans-serif;
    font-size: 21px;
    font-style: italic;
    font-weight: 700;
    margin: 0;
    padding: 32px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}

.no-access-description {
    color: #000;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    padding-top: 20px;
}

.no-access-description > A {
    color: #000;
    font-weight: bold;
}

</style>

<script>
import { keycloakService } from './services/keycloak.service'

export default {
    components: {
    },

    computed: {
        hasAccessToDashboard() {
            return keycloakService.hasAccessToDashboard()
        }
    },

    methods: {
        logout() {
            return keycloakService.logout()
        }
    }
}
</script>