export class Constants {
    
    static KEYCLOAK_BASE_URL = `${process.env.VUE_APP_KEYCLOAK_AUTH_URL}/realms/${process.env.VUE_APP_KEYCLOAK_REALM}`;

    static LAST_LOGGED_IN_EMAIL = 'last-logged-in-email'

    static BEDS_SELECTED_IDS_KEY = 'beds-selected-ids'

    static SETTINGS_BED_IDS_KEY = 'settings-bed-ids'
    static SETTINGS_SELECTED_IDS_KEY = 'settings-selected-ids'

    static TOKEN_KEY = 'vue-token';
    static REFRESH_TOKEN_KEY = 'vue-refresh-token';
    static ALERT_TIMEOUT = 5000;
    static TIMESLOT_WIDTH = 50;
    static DOCKS_AUTO_REFRESH = 30000;
    
    static ENDPOINT_USERINFO = `${process.env.VUE_APP_BASE_URL}/freshbed/1.0.0/auth/userinfo`;
    static ENDPOINT_GROUPS = `${process.env.VUE_APP_BASE_URL}/freshbed/1.0.0/groups`;
    static ENDPOINT_BEDS = `${process.env.VUE_APP_BASE_URL}/freshbed/1.0.0/beds`;
    static ENDPOINT_LOGS = `${process.env.VUE_APP_BASE_URL}/freshbed/1.0.0/logs`;
    static ENDPOINT_FIRMWARE = `${process.env.VUE_APP_BASE_URL}/freshbed/1.0.0/firmware`;
    static ENDPOINT_COMMANDS = `${process.env.VUE_APP_BASE_URL}/freshbed/1.0.0/commands`;
}