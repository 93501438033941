import Navigation from '@/components/navigation/Navigation.vue';
import { Constants } from '@/constants';
import { Freshbed } from '@/model/Freshbed';
import router from '@/router';
import { tokenService } from '@/services/token.service';
import { defineComponent } from 'vue';

import { keycloakService } from '../../services/keycloak.service';
import { bedService } from './../../services/bed.service';
import * as _ from "lodash"
import moment from "moment"

export default defineComponent({
    name: 'Beds',
    components: {
        Navigation
    },

    data() {
        return {
            filterVisibilityOpen: true,
            filterVisibilityShowActiveBeds: true,
            filterVisibilityShowInactiveBeds: true,
            loadTime: new Date(),
            beds: Array<Freshbed>(),
            selectedBedIds: Array<string>(),
            searchQuery: '',
        }
    },

    async mounted() {
        const storageData = localStorage.getItem(Constants.BEDS_SELECTED_IDS_KEY)
        if (storageData)
            this.selectedBedIds = JSON.parse(storageData)
        else
            this.selectedBedIds = []

        this.loadTime = new Date()
        this.beds = await bedService.getBeds(true, this.searchQuery)
    },

    methods: {

        toggleVisibilityFilter(): void {
            this.filterVisibilityOpen = !this.filterVisibilityOpen
        },

        toggleShowActiveBeds() {
            this.filterVisibilityShowActiveBeds = !this.filterVisibilityShowActiveBeds
            this.debounceSearch()
        },

        toggleShowInactiveBeds() {
            this.filterVisibilityShowInactiveBeds = !this.filterVisibilityShowInactiveBeds
            this.debounceSearch()
        },

        save(): void {
            localStorage.setItem(Constants.BEDS_SELECTED_IDS_KEY, JSON.stringify(this.selectedBedIds))
        },

        getUncheckedBedIdsInList(): string[] {
            const bedIds = this.beds.map(bed => bed.id)
            return _.pullAll(bedIds, this.selectedBedIds)
        },

        check(bed: Freshbed): void {
            const selectedIndex = this.selectedBedIds.indexOf(bed.id)
            if (selectedIndex > -1) {
                this.selectedBedIds.splice(selectedIndex, 1)
            } else {
                this.selectedBedIds.push(bed.id)
            }
            this.save()
        },

        checkAll(): void {
            const uncheckedBedIds = this.getUncheckedBedIdsInList()
            this.selectedBedIds = this.selectedBedIds.concat(uncheckedBedIds)
            this.save()
        },

        uncheckAll(): void {
            const bedIds = this.beds.map(bed => bed.id)
            this.selectedBedIds = _.pullAll(this.selectedBedIds, bedIds)
            this.save()
        },

        formatDate(date: Date): string {
            return moment(date).format('LLL')
        },

        editSelection(): void {
            const data = JSON.stringify(this.selectedBedIds)
            localStorage.setItem(Constants.SETTINGS_BED_IDS_KEY, data)
            localStorage.setItem(Constants.SETTINGS_SELECTED_IDS_KEY, data)
            router.push(`/settings`)
        },

        editBed(bed: Freshbed): void {
            this.selectedBedIds = []
            this.selectedBedIds.push(bed.id)
            this.save()
            this.editSelection()
        },

        logout(): void {
            tokenService.invalidateToken()
            keycloakService.logout()
        },

        debounceSearch: _.debounce(function(this: any) {
            this.performSearch()
        }, 200),

        async performSearch(): Promise<void> {
            const beds = await bedService.getBeds(true, this.searchQuery)
            if (this.filterVisibilityShowActiveBeds && !this.filterVisibilityShowInactiveBeds) {
                this.beds = beds.filter(bed => bed.isOnline)
            } else if (!this.filterVisibilityShowActiveBeds && this.filterVisibilityShowInactiveBeds) {
                this.beds = beds.filter(bed => !bed.isOnline)
            } else if (!this.filterVisibilityShowActiveBeds && !this.filterVisibilityShowInactiveBeds) {
                this.beds = []
            } else {
                this.beds = beds
            }
        }
        
    }

  });
