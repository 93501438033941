import { Firmware } from '@/model/Firmware';

import { Constants } from '../constants';
import { ApiError, apiService } from './api.service';

export class FirmwareService {

    public async getFirmware(): Promise<Firmware[]> {
        try {
            const endpoint = `${Constants.ENDPOINT_FIRMWARE}`
            const response = await apiService.get(endpoint)
            return response.data
        }catch(error) {
            throw new ApiError(error)
        }
    }
}

// Export a singleton instance in the global namespace
export const firmwareService = new FirmwareService();