<template>
    <div class="grid">
        <Navigation selected="beds"/>

        <div class="grid-content">
            <div class="grid-bed-filter">
                <div class="input">
                    <i class="material-icons-outlined">search</i>
                    <input v-model="searchQuery" v-on:input="debounceSearch" class="input-search" placeholder="Search ...">
                </div>

                <div class="bed-filter-header" v-on:click="toggleVisibilityFilter()">
                    <span>Visibility</span>
                    <div class="bed-filter-header-icon">
                        <i class="material-icons" v-if="filterVisibilityOpen">remove</i>
                        <i class="material-icons" v-else>add</i>
                    </div>
                </div>

                <div class="bed-filter-content" :class="{ 'bed-filter-hide' : !filterVisibilityOpen }">

                    <div class="bed-filter-row" v-on:click="toggleShowActiveBeds()">
                        <div class="bed-filter-icon">
                            <i class="material-icons bed-filter-icon-active" v-if="filterVisibilityShowActiveBeds">check_box</i>
                            <i class="material-icons" v-else>check_box_outline_blank</i>
                        </div>
                        <div class="bed-filter-data">
                            <span class="bed-filter-data-active">Show active beds</span>
                        </div>
                    </div>

                    <div class="bed-filter-row" v-on:click="toggleShowInactiveBeds()">
                        <div class="bed-filter-icon">
                            <i class="material-icons bed-filter-icon-active" v-if="filterVisibilityShowInactiveBeds">check_box</i>
                            <i class="material-icons" v-else>check_box_outline_blank</i>
                        </div>
                        <div class="bed-filter-data">
                            <span class="bed-filter-data-active">Show inactive beds</span>
                        </div>
                    </div>
                </div>                
            </div>

            <div class="grid-bed-result">

                <div class="grid-bed-result-content">
                    <div class="data-header">
                        <div class="data-header-icon">
                            <i class="material-icons data-header-icon-active" v-on:click="uncheckAll()" v-if="getUncheckedBedIdsInList().length == 0">check_box</i>
                            <i class="material-icons-outlined data-header-icon-inactive" v-on:click="checkAll()" v-else>check_box_outline_blank</i>
                        </div>
                        <div class="data-header-main" v-on:click="checkAll()">
                            <span>{{ beds.length }} results found</span>
                        </div>
                        <!-- TODO: Enable when we support both filter views -->
                        <!-- <div class="data-header-icon-link"><a class="data-icon-link"><i class="material-icons-outlined">view_column</i></a></div> -->
                        <div></div>
                        <!-- TODO: Enable when we support both filter views -->
                        <!-- <div class="data-header-icon-link"><a class="data-icon-link-active"><i class="material-icons-outlined">view_stream</i></a></div> -->
                        <!-- TODO: Remove when we support both filter views -->
                        <div></div>
                        <div></div>
                    </div>

                    <div class="data-row" v-for="(bed) of beds" v-bind:key="bed" v-on:click="check(bed)">
                        <div class="data-row-icon">
                            <i class="material-icons data-row-icon-active" v-if="selectedBedIds.includes(bed.id)">check_box</i>
                            <i class="material-icons data-row-icon-inactive" v-else>check_box_outline_blank</i>
                        </div>
                        <div class="data-row-icon" :title="'Last seen: ' + formatDate(bed.lastCheckedDate)">
                            <i class="material-icons-outlined" :class="{'bed-online': bed.isOnline}">king_bed</i>
                        </div>
                        <div class="data-row-main">
                            <a v-on:click.stop="editBed(bed)">
                               {{ bed.id }}
                            </a>
                            <span v-if="bed.name" :title="bed.name">
                                ({{ bed.name }})
                            </span>
                        </div>
                        <div class="data-row-secondary">{{ bed.group ? bed.group.name : '-' }}</div>
                        <div class="data-row-secondary">{{ bed.users && bed.users[0] ? bed.users[0].emailAddress : '-' }}</div>
                    </div>

                </div>

                <div class="grid-bed-result-footer">
                    <button class="button" v-on:click="editSelection()">Edit selection</button>
                </div>

            </div>
        </div>
    </div>
</template>

<script src="./beds.ts"></script>
<style src="./beds.scss" lang="scss"></style>

