<template>
    <div class="grid">
        <Navigation selected="accounts"/>

        <div class="grid-content grid-content-accounts">
            <div class="grid-accounts-filter">

                <div class="accounts-filter">
                    <div class="accounts-filter-header" v-on:click="showFilter()">
                        <span>Available beds</span>
                        <div class="accounts-filter-header-icon">
                            <i class="material-icons" v-if="open">remove</i>
                            <i class="material-icons" v-else>add</i>
                        </div>
                    </div>

                    <div class="accounts-filter-content" :class="{ 'accounts-filter-hide' : !open }">
                        <button class="button button-settings" v-on:click="checkAll()">{{ selectedBedIds.length >= beds.length ? "Deselect all" : "Select all"}}</button>

                        <div class="accounts-filter-row" v-for="(bed) of beds" v-bind:key="bed" v-on:click="check(bed)">
                            <div class="accounts-filter-icon">
                                <i class="material-icons accounts-filter-icon-active" v-if="selectedBedIds.includes(bed.id)">check_box</i>
                                <i class="material-icons" v-else>check_box_outline_blank</i>
                            </div>
                            <div class="accounts-filter-data">
                                <span class="accounts-filter-data-active" v-if="bed.checked">
                                    {{ bed.id }}
                                    <span v-if="bed.name">({{ bed.name }})</span>
                                </span>
                                <span v-else>
                                    {{ bed.id }}
                                    <span v-if="bed.name">({{ bed.name }})</span>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="note">
                        <div class="note-caption">Note</div>
                        <div class="note-icon">
                            <i class="material-icons">push_pin</i>
                        </div>
                        <span>The available beds are the result of the filter on the Freshbed tab</span>
                        <p>
                            <router-link to="/beds" class="note-link">Change filter</router-link>
                        </p>
                    </div>

                </div>

            </div>

            <div class="grid-accounts-result" v-if="this.selectedBedIds.length > 0">

                <div class="accounts-result-toggle-view">
                    <div class="accounts-result-toggle-view-inactive" :class="{ 'accounts-result-toggle-view-active' : showList }" v-on:click="toggleList()">
                        <span class="material-icons-outlined">view_stream</span>
                    </div>
                    <div class="accounts-result-toggle-view-inactive" :class="{ 'accounts-result-toggle-view-active' : !showList }" v-on:click="toggleList()">
                        <span class="material-icons-outlined">view_column_2</span>
                    </div>
                </div>

                <div v-if="!showList">
                    <div v-for="(bed) of beds" v-bind:key="bed">
                        <div class="accounts-data" v-if="selectedBedIds.includes(bed.id)">
                            <div class="accounts-data-cards-header">
                                <div class="accoutns-data-cards-header-icon">
                                    <span class="material-icons-outlined">king_bed</span>
                                </div>
                                <div class="accounts-data-cards-header-caption">
                                    {{ bed.id }}
                                    <span v-if="bed.name">({{ bed.name }})</span>
                                </div>
                                <div class="accounts-data-cards-header-amount">
                                    <span v-if="bed.users && bed.users.length == 1">1 account</span>
                                    <span v-else>{{ bed.users ? bed.users.length : "0" }} accounts</span>
                                </div>
                            </div>
                            <div class="accounts-data-cards" v-if="bed.users && bed.users.length > 0">
                                <div class="accounts-data-card" v-for="(user) of bed.users" v-bind:key="user">
                                    <div class="accounts-data-card-avatar">
                                        <span class="material-symbols-rounded accounts-data-card-avatar-icon">account_circle</span>
                                    </div>
                                    <div class="accounts-data-card-email">{{ user.emailAddress }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="showList">
                    <div v-for="(bed) of beds" v-bind:key="bed">
                        <div class="accounts-data-row" v-if="selectedBedIds.includes(bed.id)">
                            <div class="accounts-data-row-caption" v-if="selectedBedIds.length > 1">Connected users for {{ bed.id }}</div>
                            <div class="accounts-data-row-caption" v-if="selectedBedIds.length == 1">Connected users</div>
                            <div class="accounts-data-row-icon"></div>
                            <div class="accounts-data-row-multi-value">
                                <div class="value" v-if="bed.users && bed.users.length == 0">-</div>
                                <div v-for="(user) of bed.users" v-bind:key="user">
                                    <div class="value">{{ user.emailAddress }}</div>
                                </div>
                            </div>
                            <div class="accounts-data-row-icon"></div>
                        </div>
                    </div>
                </div>

                <br /><br />
            </div>

            <div class="grid-accounts-no-result" v-else>
                Please select one or more beds.
                <br />
                If there are no beds available, try to <router-link to="/beds" class="note-link">change the filter</router-link> 
            </div>

        </div>
    </div>
</template>

<script src="./accounts.ts"></script>
<style src="./accounts.scss" lang="scss"></style>