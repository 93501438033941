import { defineComponent } from 'vue'
import Navigation from '@/components/navigation/Navigation.vue'
import { Freshbed } from '@/model/Freshbed';
import { Command } from '@/model/Command';
import VueApexCharts from "vue3-apexcharts";
import { Constants } from '@/constants';
import { Log } from '@/model/Log';
import { logService } from '@/services/log.service';
import * as _ from "lodash"
import { Firmware } from '@/model/Firmware';
import { firmwareService } from '@/services/firmware.service';
import Card from '@/components/Card.vue';
import { bedService } from '@/services/bed.service';
import { keycloakService } from '@/services/keycloak.service';
import { Role } from '@/model/Role';
import { commandService } from '@/services/command.service';
import moment from 'moment'

const MIN_TEMPERATURE = 22.5
const MAX_TEMPERATURE = 30
const MIN_FAN_SPEED = 10
const MAX_FAN_SPEED = 40

export default defineComponent({
    name: 'Settings',
    components: {
        Navigation,
        apexchart: VueApexCharts,
        Card
    },

    data() {
        return {
            beds: Array<Freshbed>(),
            bedIds: Array<string>(),
            selectedBedIds: Array<string>(),
            logs: Array<Log>(),
            firmware: Array<Firmware>(),
            open: true,
            showFirmwareCard: false,
            showCommandCard: false,
            id: undefined as undefined | string,
            name: undefined as undefined | string,
            groupName: undefined as undefined | string,
            users: [] as string[],
            lastSeen: undefined as undefined | string,
            dateWebsocketConnected: undefined as undefined | string,
            currentBedTemperature: undefined as undefined | string,
            currentBedHumidity: undefined as undefined | string,
            targetTemperature: undefined as undefined | string,
            chartTemperature: [0],
            currentFanSpeed: undefined as undefined | string,
            targetFanSpeed: undefined as undefined | string,
            chartFanSpeed: [0],
            currentRoomTemperature: undefined as undefined | string,
            currentRoomHumidity: undefined as undefined | string,
            extendedLoggingEnabled: undefined as undefined | string,
            versions: [] as string[],
            espVersions: [] as string[],
            mainVersions: [] as string[],
            remoteVersions: [] as string[],
            pendingBedCommands: [] as Command[],
            availableBedCommands: [] as Command[],
          chartOptions: {
            states: {
                hover: {
                    filter: {
                        type: 'none',
                    }
                }
            },
            chart: {
              type: 'radialBar',
            },
            plotOptions: {
              radialBar: {
                startAngle: -110,
                endAngle: 110,
                track: {
                  background: "#CDBFB5",
                  strokeWidth: '100%',
                  margin: 20, // margin is in pixels
                },
                dataLabels: {
                  name: {
                    show: false
                  },
                  value: {
                      show: false
                  }
                }
              }
            },
            grid: {
              padding: {
                top: -10
              }
            },
            fill: {
              colors: ['#926D4F']
            },
            labels: ['Average Results']
          }
        }
    },

    async mounted() {
        this.selectedBedIds = []
        this.beds = []

        const bedIdsData = localStorage.getItem(Constants.SETTINGS_BED_IDS_KEY)
        if (bedIdsData)
        this.bedIds = JSON.parse(bedIdsData)

        const selectedIdsData = localStorage.getItem(Constants.SETTINGS_SELECTED_IDS_KEY)
        if (selectedIdsData)
            this.selectedBedIds = JSON.parse(selectedIdsData)

        const beds = await bedService.getBeds()
        this.beds = beds.filter(bed => this.bedIds.includes(bed.id))

        if (this.isFreshbedAdmin())
            this.firmware = await firmwareService.getFirmware()

        this.updateData()
    },

    methods: {

        map(x: number, inMin: number, inMax: number, outMin: number, outMax: number): number {
            return (x - inMin) * (outMax - outMin) / (inMax - inMin) + outMin
        },

        save(): void {
            localStorage.setItem(Constants.SETTINGS_SELECTED_IDS_KEY, JSON.stringify(this.selectedBedIds))
        },

        isFreshbedAdmin(): boolean{
            return keycloakService.isAuthorizedForRole(Role.FreshbedAdmin)
        },

        check(bed: Freshbed): void {
            const selectedIndex = this.selectedBedIds.indexOf(bed.id)
            if (selectedIndex > -1) {
                this.selectedBedIds.splice(selectedIndex, 1)
            } else {
                this.selectedBedIds.push(bed.id)
            }
            this.save()
            this.updateData()
        },

        checkAll(): void {
            if (this.beds.length == this.selectedBedIds.length)
                this.selectedBedIds = []
            else
                this.selectedBedIds = this.beds.map(bed => bed.id)
            this.save()
            this.updateData()
        },

        async updateData(): Promise<void> {
            const selectedBeds = this.beds.filter(bed => this.selectedBedIds.includes(bed.id))

            if (selectedBeds.length > 0)
                this.logs = await logService.getLogs(selectedBeds)

            if (selectedBeds.length == 0 || this.logs.length == 0)
            {
                this.chartTemperature = [0]
                this.lastSeen = undefined
                this.dateWebsocketConnected = undefined
                this.currentBedTemperature = undefined
                this.currentBedHumidity = undefined
                this.targetTemperature = undefined
                this.chartFanSpeed = [0]
                this.currentFanSpeed = undefined
                this.targetFanSpeed = undefined
                this.currentRoomTemperature = undefined
                this.currentRoomHumidity = undefined
                this.extendedLoggingEnabled = 'no'
                this.versions = []
                this.espVersions = []
                this.mainVersions = []
                this.remoteVersions = []
                this.pendingBedCommands = []
                this.id = undefined
                this.name = undefined
                this.groupName = undefined
                this.users = []
                return
            }

            if (selectedBeds.length == 1) {
                this.lastSeen = moment(selectedBeds[0].lastCheckedDate).format('L LTS')
                this.dateWebsocketConnected = selectedBeds[0].dateWebsocketConnected ? moment(selectedBeds[0].dateWebsocketConnected).format('L LTS') : 'Not connected'
            } else {
                this.lastSeen = undefined
                this.dateWebsocketConnected = undefined
            }

            const selectedOneBed = selectedBeds.length == 1 && this.logs.length == 1
            this.id = selectedOneBed ? this.logs[0].id : undefined
            this.name = selectedOneBed ? selectedBeds[0].name : undefined
            this.groupName = selectedOneBed ? selectedBeds[0].group?.name : undefined

            const currentBedTemperature = _.meanBy(this.logs, 'currentBedTemperature')
            this.chartTemperature = [ this.map(currentBedTemperature, MIN_TEMPERATURE, MAX_TEMPERATURE, 0, 100) ]
            this.currentBedTemperature = currentBedTemperature.toFixed(1)
            this.currentBedHumidity = _.meanBy(this.logs, 'currentBedHumidity').toFixed(1)
            this.targetTemperature = _.meanBy(this.logs, 'targetTemperature').toFixed(1)

            const currentFanSpeed = _.meanBy(this.logs, 'currentFanSpeed')
            this.chartFanSpeed = [ this.map(currentFanSpeed, MIN_FAN_SPEED, MAX_FAN_SPEED, 0, 100) ]
            this.currentFanSpeed = currentFanSpeed.toFixed(0)
            this.targetFanSpeed = _.meanBy(this.logs, 'targetFanSpeed').toFixed(0)

            this.espVersions = _.uniqBy(this.logs, 'espVersion').map(log => log.espVersion)
            this.mainVersions = _.uniqBy(this.logs, 'mainVersion').map(log => log.mainVersion)
            this.remoteVersions = _.uniqBy(this.logs, 'remoteVersion').map(log => log.remoteVersion)

            this.currentRoomTemperature = _.meanBy(this.logs, 'currentRoomTemperature').toFixed(1)
            this.currentRoomHumidity = _.meanBy(this.logs, 'currentRoomHumidity').toFixed(1)

            const hasExtendedLoggingEnabled = _.find(selectedBeds, bed => bed.extendedLoggingEnabled == true) != undefined
            const hasExtendedLoggingDisabled = _.find(selectedBeds, bed => bed.extendedLoggingEnabled == false) != undefined
            if (hasExtendedLoggingEnabled && hasExtendedLoggingDisabled) {
                this.extendedLoggingEnabled = "indeterminate"
            } else if (hasExtendedLoggingEnabled) {
                this.extendedLoggingEnabled = "yes"
            } else {
                this.extendedLoggingEnabled = "no"
            }

            await this.getUsers()

            this.versions = _.uniqBy(this.beds, 'firmwareVersion').map(bed => bed.firmwareVersion)
            
            if (this.isFreshbedAdmin()) {
                const promises = this.selectedBedIds.map(bedId => commandService.getPendingWriteCommandsForBed(bedId))
                const commandsPerBed = (await Promise.all(promises)).flat()
                this.pendingBedCommands = _.uniqBy(commandsPerBed, 'id')
            } else {
                this.pendingBedCommands = []
            }
        },

        async getUsers(): Promise<void> {
            const beds = await bedService.getBeds(true)
            this.beds = beds.filter(bed => this.bedIds.includes(bed.id))
            
            this.users = []
            this.beds.forEach(bed => {
                bed.users.forEach(user => {
                    this.users.push(user.emailAddress)
                })
            })
        },

        showFilter(): void {
            this.open = !this.open
        },

        toggleFirmwareCard() {
            this.showFirmwareCard = !this.showFirmwareCard
        },

        toggleCommandCard() {
            this.showCommandCard = !this.showCommandCard
            if (this.showCommandCard) {
                this.loadAvailableBedCommands()
            }
        },

        async toggleExtendedLogging() {
            let enabled = false
            if (this.extendedLoggingEnabled == 'no') {
                enabled = true
            }
            this.extendedLoggingEnabled = enabled ? 'yes' : 'no'
            const selectedBeds = this.beds.filter(bed => this.selectedBedIds.includes(bed.id))
            try {
                const apiUpdates = this.selectedBedIds.map(bedId => bedService.setExtendedLogging(bedId, enabled))
                await Promise.all(apiUpdates)
                for (const bed of selectedBeds) {
                    bed.extendedLoggingEnabled = enabled
                }    
            }
            catch(error) {
                console.log(error)
            }
        },

        async updateFirmware(firmware: Firmware, force: boolean) {
            this.showFirmwareCard = false
            try {
                const apiUpdates = this.selectedBedIds.map(bedId => commandService.changeBedFirmware(bedId, firmware, force))
                await Promise.all(apiUpdates)
            }
            catch(error) {
                console.log(error)
            }
            this.updateData()
        },

        async loadAvailableBedCommands() {
            this.availableBedCommands = []
            const commands = await commandService.getCommandsForBeds(this.selectedBedIds)
            this.availableBedCommands = commands.filter(command => command.isVisibleInUI && command.isWritable)
        },

        async executeCommand(command: Command) {
            this.showCommandCard = false
            try {
                const apiUpdates = this.selectedBedIds.map(bedId => commandService.executeBedCommand(bedId, command, false))
                await Promise.all(apiUpdates)
            }
            catch(error) {
                console.log(error)
            }
            this.updateData()
        },

        getPendingFirmwareUpdates() {
            return this.pendingBedCommands.filter(command => command.id == "request_firmware_update" || command.id == "force_firmware_update")
        },

        getVisiblePendingCommands() {
            return this.pendingBedCommands.filter(command => command.isVisibleInUI)
        },

    }

});
